<template>
  <div>
    <h3 ref="ia">
      Free Body Diagram
    </h3>
    <p>
      A free-body diagram is a simplified graphical illustration that shows all the external forces acting on a body or a system of bodies.
      A free-body diagram is also known as 'FBD' &mdash; the commonly used shorthand notation for it. <br>
      To obtain the free-body diagram of a body, we isolate that body from its surrounding objects and replace those objects by the forces they exert on the body.<br>
      Note that while drawing the free-body diagram of a system of bodies,
      we consider only the external forces on the system &mdash; that is, the forces or moments exerted by bodies outside of the system.
      <br>
      Below are the two steps that we need to follow in order to draw the free body diagram of an object.
      <ul class="a">
        <li> <h5>Step 1 </h5> </li>
        First identify all the surrounding objects that are in contact with the body or the system of bodies.

        <li> <h5>Step 2 </h5> </li>
        Then replace the surrounding objects one by one by the forces exerted by them on the body.
        Each force on the body is indicated by a vector arrow showing the direction and point of application of the force.
      </ul>
    </p>
    <h3 ref="ja">
      Purpose of a Free Body Diagram
    </h3>
    <p>
      In mechanics, a free-body diagram is commonly used in an exercise known as balance of forces and moments.
      Balance of forces or moments is used for calculating unknown internal or reactionary forces.
      This exercise requires accounting for all the forces and moments acting on an object.
      Drawing a free-body diagram is the first step in this exercise and is used to visualize all the external forces and moments acting on the object.
    </p>
    <h3 ref="ka">
      Example: Free Body Diagram of an Inclined Wooden Plank
    </h3>
    <p>
      In this example, we will consider a massless wooden plank that is resting on a wall. The plank is in contact with two external objects: the floor and the wall.
      To construct the free body diagram of the plank, we will remove these surrounding objects and replace them by the forces exerted by them on the plank.
    </p>
    <v-layout justify-center>
      <v-img src="/assets/FBD-1.png"
             max-height="350px"
             max-width="350px"
             contain
      />
    </v-layout>
    <v-layout justify-center>
      <h5 style="text-align:center">
        Figure 1(a): Goal is to draw the FBD of a plank inclined against a brick wall
      </h5>
    </v-layout>
    <br>
    <p>
      First, we remove the wall and replace it by the force exerted by it on the plank. Note that the contact between the plank and the wall is assumed frictionless.
      So, the wall does not exert any frictional force on the plank.
      The resultant configuration is shown in Figure 1(b).
    </p>
    <v-layout justify-center>
      <v-img src="/assets/FBD-2.png"
             max-height="350px"
             max-width="350px"
             contain
      />
    </v-layout>
    <v-layout justify-center>
      <h5 style="text-align:center">
        Figure 1(b): First, we replace the wall by the force it exerts on the plank.
      </h5>
    </v-layout>
    <br>
    <p>
      Next, we remove the floor and replace it by the force exerted by it on the plank. This completes the FBD for the plank.
      The resultant configuration is shown in Figure 1(c).
    </p>
    <v-layout justify-center>
      <v-img src="/assets/FBD-3.png"
             max-height="350px"
             max-width="350px"
             contain
      />
    </v-layout>
    <v-layout justify-center>
      <h5 style="text-align:center">
        Figure 1(c): Next, we replace the floor by the force it exerts on the plank.
      </h5>
    </v-layout>
    <h3 ref="pg">
      MagicGraph | Monkey on a Plank
    </h3>
    <p>
      Monkey <i>Mo</i> is being chased by a angry bull <i>Bo</i>. <br> To save himself from the bull, Monkey <i>Mo</i> climbs up a wooden plank that is inclined against a brick wall. <br>
      Bull <i>Bo</i> can't climb up the plank so, in an effort to bring monkey <i>Mo</i> down, he charges at the plank. <br>
      Your goal is to draw the free-body diagram of the plank accounting for all the external forces acting on it.
      You may assume that the plank is massless, and monkey <i>Mo</i> has a mass \(m\).
      The gravity acts downward (as shown in the MagicGraph).
    </p>

    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Free Body Diagram';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Free Body Diagram',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
